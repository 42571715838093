<template>
<header class="site-header optech-header-section site-header--menu-right optech-header-two" id="sticky-menu">
  <div class="optech-header-top p-0">
    <div class="container">
      <div class="extra-border-bottom">
        <div class="optech-header-info-wrap">
          <div class="optech-header-info ">
            <ul>
              <li><i class="ri-map-pin-2-fill"></i>Belfast, Northern Ireland</li>
            </ul>
          </div>
          <div class="optech-header-info ">
            <ul>
              <!-- <li><a href="tel:123"><i class="ri-phone-fill"></i>518-564-3200</a></li> -->
              <li><a href="mailto:info@local-wifi.com"><i class="ri-mail-fill"></i>info@local-wifi.com</a></li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="optech-header-bottom bg-white" id="lw-custom-header">
    <div class="container">
      <nav class="navbar site-navbar">
        <!-- Brand Logo-->
        <div class="brand-logo">
          <a href="index.html">
            <img src="assets/images/logo/logo-white.png" alt="" class="light-version-logo" id="lw-custom-logo">
          </a>
        </div>
        <div class="menu-block-wrapper">
          <div class="menu-overlay"></div>
          <nav class="menu-block" id="append-menu-header">
            <div class="mobile-menu-head">
              <div class="go-back">
                <i class="fa fa-angle-left"></i>
              </div>
              <div class="current-menu-title"></div>
              <div class="mobile-menu-close">&times;</div>
            </div>
            <ul class="site-menu-main light-color" id="lw-custom-menu">
                <li class="nav-item">
                    <a href="/" class="nav-link-item">Home</a>
                </li>
              <!-- <li class="nav-item">
                <a href="contact-us.html" class="nav-link-item">Our Story</a>
              </li> -->
            </ul>
          </nav>
        </div>
        <div class="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
          <a class="optech-default-btn optech-header-btn" href="https://forms.gle/xtB5J5FoeTjoKK4h9" target="_blank" data-text="Get in Touch"><span class="btn-wraper">Get in Touch</span></a>
        </div>
        <!-- mobile menu trigger -->
        <div class="mobile-menu-trigger light-color">
          <span></span>
        </div>
        <!--/.Mobile Menu Hamburger Ends-->
      </nav>
    </div>
  </div>

</header>
</template>

<script>
export default {
    methods: {
        addMenuHandler : ()=>{
            let script = document.createElement("script");
            script.src = "/assets/js/menu/menu.js";
            document.head.appendChild(script);
        }
    },
    mounted(){

        this.addMenuHandler();

        let headerElement = document.querySelector("#lw-custom-header");
        let menuElement = document.querySelector("#lw-custom-menu");
        let logoElement = document.querySelector("#lw-custom-logo");

        headerElement.classList = "optech-header-bottom";
        logoElement.src = "/assets/images/logo/logo-white.png";
        menuElement.classList = "site-menu-main light-color";

        addEventListener("scroll", (event) => {
            if(window.scrollY > 0){
                headerElement.classList = "optech-header-bottom bg-white";
                logoElement.src = "/assets/images/logo/logo.png";
                menuElement.classList = "site-menu-main dark-color";
                document.documentElement.style.setProperty('--mobile-menu-trigger-colour', '#13111a');
            }else{
                headerElement.classList = "optech-header-bottom";
                logoElement.src = "/assets/images/logo/logo-white.png";
                menuElement.classList = "site-menu-main light-color";
                document.documentElement.style.setProperty('--mobile-menu-trigger-colour', 'white');
            }
        });
    }
}
</script>